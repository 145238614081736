import { set_state, get } from "./events"

export function sign_in() {
	const params = [
		["openid.ns", "http://specs.openid.net/auth/2.0"],
		["openid.mode", "checkid_setup"],
		["openid.return_to", `${window.location.protocol}//${window.location.host}/api/auth/steam_login`],
		["openid.realm", `${window.location.protocol}//${window.location.host}`],
		["openid.identity", "http://specs.openid.net/auth/2.0/identifier_select"],
		["openid.claimed_id", "http://specs.openid.net/auth/2.0/identifier_select"],
	] as const

	window.location.href = `https://steamcommunity.com/openid/login?${params.map(([k, v]) => `${k}=${v}`).join("&")}`
}

export async function sign_out() {
	await fetch("/api/auth/logout", { method: "POST" })

	set_state("authorized", { authorized: false })

	window.document.cookie = "token=; Max-Age=-99999999;"
}

export async function init_auth() {
	const authorized = await get<TAuthorizedSummary>("/api/auth/authorized");

	if (authorized) {
		set_state("authorized", authorized)
	}
}