import React from "react"
import { useState } from "react"
import { useSearchParams, Link } from "react-router-dom"
import { getLanguage, localize, localize_price } from "../components/localization"
import { R_get, R_subscribe, get, postJson } from "../components/events"
import { R_on_click_outside, create_order, background_style, is_nothing, create_present_order, getOrderStatus } from "../components/utils"
import { SizedImage, Loading, SwitchWithValuesBtn, SortableTable } from "./samples"
import { set_state, get_state } from "../components/events";
import { URLSearchParams } from "url"
import { ItemBuildContainer } from "./itemBuilds";
import { type } from "os"

function clearSearchParams(params: URLSearchParams): URLSearchParams {
	params.delete("popup")
	params.delete("productId")
	params.delete("heroName")
	params.delete("talentName")
	params.delete("present")
	params.delete("present_history")

	return params;
}

const Popup: React.FC<{ children?: React.ReactNode, withAuth?: boolean }> = props => {
	const [searchParams, setSearchParams] = useSearchParams()
	const popupRef = React.useRef<HTMLDivElement>(null)
	const authorized = R_subscribe("authorized")

	R_on_click_outside(popupRef, () => {
		const emptySearchParams = clearSearchParams(searchParams);
		setSearchParams(emptySearchParams)
	})

	if (!authorized?.authorized) {
		const emptySearchParams = clearSearchParams(searchParams);
		setSearchParams(emptySearchParams)
		return <></>
	}

	return (
		<div className="z-[20] h-[100vh] fixed top-0 left-0 right-0 flex bg-black bg-opacity-80 flex flex-col overflow-y-scroll">
			<div ref={popupRef} className="mx-auto mt-[5vh] mb-[20vh]">
				{props.children}
			</div>
			<div className="h-[20vh]" />
		</div>
	)
}

export const PresentPopup: React.FC<{
	type: TPresentPopupPage
}> = props => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [stage, setStage] = useState("main" as TPresentPopupPageStage)
	const [presentPopupPage, setPresentPopupPage] = React.useState(props.type);

	if (getLanguage() !== "ru") {
		clearSearchParams(searchParams)
		return <></>;
	}

	const products = R_get<TProduct[]>("/api/payment/data/products")!

	let bodyBlock;
	let allHeaders = ['buy', 'history'] as TPresentPopupPage[];

	switch (presentPopupPage) {
		case "buy":
			switch (stage) {
				case "main":
					bodyBlock = <PresentBuyMainStagePopup products={products}
						onOrderCreate={(orderId) => {
							setStage("order_wait");
						}} />
					break;
				case "order_wait":
					bodyBlock = <PresentBuyOrderWaitStagePopup />
					break;
			}

			break;
		case "history":
			bodyBlock = <PresentHistoryPopup />
			break;
	}

	return <Popup>
		<div className="bg-gradient-to-b w-[120vh] from-[#242E33] to-[#0E1619] border rounded-md border-[#212121] shadow-custom-xs p-5 text-[#ddd] font-semibold text-lg text-shadow">
			<div className={`grid grid-cols-2`}>
				{allHeaders.map((h, i) => (
					<PresentHeaderPopup presentPopupPage={presentPopupPage} onSelect={() => setPresentPopupPage(h)} type={h} />
				))}
			</div>
			{bodyBlock}
		</div>
	</Popup >
}


export const PresentHeaderPopup: React.FC<{
	type: TPresentPopupPage,
	onSelect: any,
	presentPopupPage: TPresentPopupPage
}> = props => {

	let titleLocalize;

	switch (props.type) {
		case "buy":
			titleLocalize = "buy_present_buy_title"
			break;
		case "history":
			titleLocalize = "buy_present_history_title"
			break;
	}

	const isActive = props.type == props.presentPopupPage
		? "present-header-active"
		: "";

	return <div className={`pb-8 present-header ${isActive} mb-5`}
		onClick={() => props.onSelect(props.type)}
	>
		<span className={`${isActive ? "opacity-[1]" : "opacity-[0.2]"}`}>
			{localize(titleLocalize)}
		</span>
	</div>
}

export const PresentBuyMainStagePopup: React.FC<{
	products: TProduct[]
	onOrderCreate: (orderId: number) => any
}> = props => {
	const [isCoupon, setIsCoupon] = React.useState(false);
	const [isBtnActive, setIsBtnActive] = React.useState(false);
	const [giftPlayerId, setGiftPlayerIdState] = React.useState(null as any);
	const [giftMessage, setGiftMessageState] = React.useState('');
	const [selectedProduct, setSelectedProduct] = React.useState({} as TProduct);

	function setGiftPlayerId(event: any) {
		let val = event.target.value.trim() as string;
		val = val.substring(0, 12);

		setGiftPlayerIdState(val);
		setIsBtnActive(val.length >= 8 && val.length <= 12 && !is_nothing(selectedProduct?.productId));
	}

	function trySetSelectedProduct(product: TProduct) {
		setSelectedProduct(product);

		const isProductValid = !is_nothing(product?.productId);

		if (isCoupon)
			setIsBtnActive(isProductValid);
		else {
			setIsBtnActive(isProductValid && giftPlayerId?.length >= 8 && giftPlayerId?.length <= 12);
		}
	}

	function setGiftMessage(event: any) {
		let val = event.target.value as string;
		val = val.substring(0, 140);

		setGiftMessageState(val);
	}

	function switchTongle() {
		setSelectedProduct({} as TProduct);
		setGiftPlayerIdState(null);
		setIsBtnActive(false);
		setIsCoupon(!isCoupon);
	}

	const language = R_subscribe("language")
	const products = props.products;

	let currency: TCurrency = "usd"

	if (language === "ru") {
		currency = "rub"
	}

	return products ? <div>
		<div className="flex">
			<SwitchWithValuesBtn
				leftValue={localize("buy_present_gift_type")}
				rigthValue={localize("buy_present_coupon_type")}
				isOn={isCoupon}
				handleToggle={() => switchTongle()}
			/>
		</div>
		<div>
			{
				isCoupon
					? <></>
					: <div className="flex flex-col">
						<span className="mt-4">
							{localize("buy_present_gift_player_id")}
							<span className="text-xs">
								{localize("buy_present_gift_player_id_support")}
							</span>
						</span>
						<input className="text-input-nobg shadow-custom-xs rounded-md mt-2 text-start"
							type="number"
							value={giftPlayerId || undefined}
							onChange={(e) => setGiftPlayerId(e)} />
						<span className="mt-4">
							{localize("buy_present_gift_message")}
							<span className="text-xs">
								{localize("buy_present_gift_message_support")}
							</span>
						</span>
						<input className="text-input-nobg shadow-custom-xs rounded-md mt-2"
							value={giftMessage}
							type="text"
							onChange={(e) => setGiftMessage(e)} />
					</div>
			}
			<div className="grid grid-cols-4 items-center mt-8 gap-4">
				{products ? products.map(s => <BuyPresentProductCard
					product={s}
					currency={currency}
					onSelect={(product) => trySetSelectedProduct(product)}
					selectedProduct={selectedProduct}
				/>
				) : <Loading />}
			</div>
			<div className="mt-5">
				<button
					className={`${isBtnActive ? "hover:brightness-[120%]" : ""}
				mt-1 w-[100%] rounded-md bg-gradient-to-r from-[#d35f04] to-[#d0af02]
				font-bold text-white text-shadow-xl p-2`}
					disabled={!isBtnActive}
					onClick={() => {
						create_present_order(selectedProduct.productId,
							currency,
							isCoupon ? "coupon" : "gift",
							giftPlayerId || undefined,
							giftMessage
						).then((data) => {
							if (!data.hasError)
								props.onOrderCreate(data?.orderId!)
						});
					}}
				>
					{selectedProduct.productId
						? localize("buy_present_oplata_ready").replace("%price%", localize_price(selectedProduct.prices[currency], currency))
						: localize("buy_present_oplata_wait")
					}
				</button>
			</div>
			<p className="text-end text-xs">
				{localize("buy_present_oplata_only_ru")}
			</p>
		</div>
	</div> : <Loading />
}

export const PresentBuyOrderWaitStagePopup: React.FC = () => {
	return <div className="mt-12 text-[#ddd] font-semibold text-xxl center text-center h-[20vh]"
		dangerouslySetInnerHTML={{ __html: localize("buy_present_order_wait") }}
	>
	</div>
}

const BuyPresentProductCard: React.FC<{
	product: TProduct
	currency: TCurrency
	onSelect: (product: TProduct) => any,
	selectedProduct: TProduct
}> = props => {
	const discount = props.product.extraData.discount ? props.product.extraData.discount.toString() : ""
	const price = props.product.prices[props.currency].toString();
	const productName = props.product.productName;
	const productId = props.product.productId;
	const isActive = props.selectedProduct == props.product;

	let productTitle;

	switch (productName) {
		case "dota_plus":
			productTitle = localize(`buy_product_${productId}`);
			break;
		case "shards":
			productTitle = localize("buy_shards_product_desc").replace("%amount%", props.product.amount.toString());
			break;
	}

	return (
		<div className={`bg-[#212121] rounded-md border border-[#212121] shadow-custom-xs
			p-2 flex flex-col items-center relative ${isActive ? "opacity-[1]" : "opacity-[0.4] hover:opacity-[0.8]"} cursor-pointer`}
			onClick={() => props.onSelect(props.product)}
		>
			<div className="mx-1 w-40 h-[5.7rem] flex justify-center">
				<SizedImage
					className=""
					src={`/images/products/${productId}.png`}

				/>
				{discount !== "" ?
					<div className="rounded-t-md bg-gradient-to-r from-transparent to-[#71f33790] flex h-10 w-16 absolute fixed top-0 left-0 right-0 ml-auto text-right flex flex-col px-2 py-1 text-[#ddd] text-shadow font-bold text-lg">
						{`-${discount}%`}
					</div>
					: <></>
				}
			</div>
			<div className="mt-2 text-4 font-semibold uppercase text-white text-shadow-white"
				dangerouslySetInnerHTML={{
					__html: productTitle
				}}
			>
			</div>
			{localize_price(price, props.currency)}
		</div>
	)
}

export const PresentHistoryPopup: React.FC = () => {
	const histories = R_get<TPresentHistory[]>("/api/payment/present_history")!

	return histories
		? <SortableTable
			columns={[
				{
					title: localize("buy_present_history_table_header_type"),
					valueFunc: x => x == 2 ? "Подарок" : "Промокод"
				},
				{
					title: localize("buy_present_history_table_header_promocode"),
					valueFunc: x => x
				},
				{
					title: localize("buy_present_history_table_header_created_at"),
					valueFunc: x => x
				},
				{
					title: localize("buy_present_history_table_header_consumer_player_id"),
					valueFunc: x => x
				},
				{
					title: localize("buy_present_history_table_header_consumer_at"),
					valueFunc: x => x
				},
			]}
			rows={histories.map(h => [
				h.type,
				h.code,
				h.createdAt,
				h.consumerPlayerId,
				h.usedAt
			])}
			tableClassName="leaderboard-table"
			headCellClassName={sorted => `
			uppercase
			px-3
			text-xl
			text-[#fedf84]
			${sorted ? `
				from-orange-500
				to-orange-600
				cursor-pointer
			` : `
				hover:from-white
				hover:to-gray-300
				cursor-pointer
			` }
		` }
			bodyRowClassName={`bg-[length:99%_60%] bg-no-repeat bg-center `}
			bodyCellClassName="text-center px-5 leaderboard-player-block"
		/> : <Loading />
}

export const PromocodePopup: React.FC = () => {
	const [promoResult, setPromoResult] = useState('');
	const [promoResultColor, setPromoResultColor] = useState('');
	const [isActive, setActive] = useState(false);
	const [inputValue, setInputValue] = useState('');

	function setValue(event: any) {
		const val = event.target.value.trim();
		setInputValue(val);
		setActive(!is_nothing(val));
	}

	async function handlePromocodeResult() {
		const result = await postJson<{
			status: number
			ruResponse: string
			engResponse: string
		}>("/api/promo", { code: inputValue }, "json");

		const promoResultText = getLanguage() === 'ru'
			? result!.ruResponse
			: result!.engResponse;

		const promoResultColor = result!.status === 0
			? 'text-green'
			: 'text-red';

		setPromoResultColor(promoResultColor);
		setPromoResult(promoResultText);
	}

	return (
		<Popup>
			<div className="lg:w-[85vh] bg-gradient-to-b from-[#242E33] to-[#0E1619] border rounded-md border-[#212121] shadow-custom-xs p-5 text-[#ddd] font-semibold text-lg text-shadow">
				<div className={`dota-plus-small pb-8 text-[#fedf84] text-3xl lg:text-5xl font-bold text-shadow text-center`}>
					{localize("dota_promocode_title")}
				</div>
				<div className="flex flex-col space-y-5 lg:space-y-0 lg:flex-row items-center lg:space-x-5 mt-8 promocode-main-block mx-8">
					<input className="text-input w-[100%]"
						value={inputValue}
						onChange={(e) => setValue(e)} />
					<button
						className={`
							${isActive ? 'active brightness-100 hover:brightness-125' : 'brightness-100'}
							w-[100%] lg:w-[30%] dota-plus-button-text-xs h-12 bg-gradient-to-r from-[#e67c00] to-[#fff442] shadow-gold
							transition transition-all ease-linear duration-100
							text-white text-shadow-xl` }
						onClick={async () => await handlePromocodeResult()}
						disabled={!isActive}>
						{localize("dota_promocode_send_btn")}
					</button>
				</div>
				<div className="text-center mt-5 font-bold h-[52px] lg:h-[26px]">
					<p className={`promocode-response text-xl ${promoResultColor}`}>{promoResult}</p>
				</div>
				<a target="_blank" rel="noopener noreferrer" href="https://t.me/Dota1x6"
					className="items-center text-left mt-8 flex flex-row lg:w-[80%] text-[14px] leading-none">
					<SizedImage
						src="/images/socials/telegram.png"
						className="flex pr-3"
						imgClassName="w-8 h-8"
					/>
					{localize("dota_promocode_telegram")}
				</a>
			</div>
		</Popup>
	)
}

export const NoticePopup: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const code = searchParams.get("noticeCode")

	if (is_nothing(code)) {
		searchParams.delete("popup")
		setSearchParams(searchParams)

		return <></>
	}

	const goodCode = {
		0: "success_payment"
	}[code]

	return (
		<Popup>
			<div className="bg-gradient-to-b from-[#242E33] to-[#0E1619] border rounded-md border-[#212121] shadow-custom-xs p-5 flex flex-col items-center p-5 text-[#ddd] font-semibold text-lg text-shadow">
				<SizedImage src={`/images/${goodCode ? "like" : "dislike"}.png`} />
				<div>
					{goodCode ?
						localize(`notice_${goodCode}`)
						:
						localize(`notice_error`).replace("%error_code%", code)
					}
				</div>
			</div>
		</Popup>
	)
}

export const PaymentPatreonPopup: React.FC<{}> = props => {
	const language = R_subscribe("language")
	const authorized = R_subscribe("authorized")
	const [searchParams, setSearchParams] = useSearchParams()
	const productId = searchParams.get("productId") as TPopupType
	const productData = R_get(`/api/payment/data/product?productId=${productId || ""}`)

	let url: string | undefined = undefined

	if (productData) {
		if (language === "ru") {
			url = productData.extraData.rubLotUrl
		} else {
			url = productData.extraData.usdLotUrl
		}
	}

	if (!productId || !authorized || !authorized.authorized) {
		searchParams.delete("popup")
		setSearchParams(searchParams)

		return <></>
	}

	return (
		<Popup>
			<div className="bg-gradient-to-b from-[#242E33] to-[#0E1619] border rounded-md border-[#212121] shadow-custom-xs p-5 flex flex-col items-center p-5 text-[#ddd] font-semibold text-lg text-shadow">
				<div className="w-full flex flex-col items-center">
					<div className="w-[60rem] text-center" dangerouslySetInnerHTML={{ __html: localize("payment_patreon_description") }} />
				</div>
				<div className="mt-8 flex flex-col py-4 px-8 bg-gray-900 shadow-inner-custom">
					<div dangerouslySetInnerHTML={{ __html: localize("payment_uah_id").replace("%id%", authorized.playerId) }} />
					<div dangerouslySetInnerHTML={{ __html: localize("payment_uah_funpay").replaceAll("%url%", "https://www.patreon.com/ShardsShop") }} />
				</div>
				<SizedImage src={`/images/patreon_${language || "en"}.png`} className="mt-8 bg-[length:100%_100%]" />
			</div>
		</Popup>
	)
}

export const PaymentFunPayPopup: React.FC<{}> = props => {
	const language = R_subscribe("language")
	const authorized = R_subscribe("authorized")
	const [searchParams, setSearchParams] = useSearchParams()
	const productId = searchParams.get("productId") as TPopupType
	const productData = R_get(`/api/payment/data/product?productId=${productId || ""}`)

	let url: string | undefined = undefined

	if (productData) {
		if (language === "ru") {
			url = productData.extraData.rubLotUrl
		} else {
			url = productData.extraData.usdLotUrl
		}
	}

	if (!productId || !authorized || !authorized.authorized) {
		searchParams.delete("popup")
		setSearchParams(searchParams)

		return <></>
	}

	return (
		<Popup>
			<div className="bg-gradient-to-b from-[#242E33] to-[#0E1619] border rounded-md border-[#212121] shadow-custom-xs p-5 flex flex-col items-center p-5 text-[#ddd] font-semibold text-lg text-shadow">
				<div className="w-full flex flex-col">
					<div className="w-[60rem] text-center" dangerouslySetInnerHTML={{ __html: localize("payment_uah_description") }} />
				</div>
				<div className="mt-8 flex flex-col py-4 px-8 bg-gray-900 shadow-inner-custom">
					<div dangerouslySetInnerHTML={{ __html: localize("payment_uah_id").replace("%id%", authorized.playerId) }} />
					<div dangerouslySetInnerHTML={{ __html: localize("payment_uah_funpay").replaceAll("%url%", url || `/?popup=notice&noticeCode=${1}`) }} />
				</div>
				<SizedImage src={`/images/funpay_${language || "en"}.png`} className="mt-8 bg-[length:100%_100%]" />
			</div>
		</Popup>
	)
}

const PaymentMethodButton: React.FC<{
	productId: string
	sym: string
	desc: string
	large?: boolean
	disabled?: boolean
	onClick: () => void
}> = props => {
	return (
		<div className="flex flex-col">
			<button
				disabled={props.disabled || false}
				className={`mx-5 my-2 w-[12rem] h-[6rem] bg-gradient-to-r from-[#d62c00] to-[#ff7f00] ${props.large ? "text-6xl" : "text-4xl"} font-bold text-shadow-xl p-2`}
				onClick={props.onClick}
			>{props.sym}</button>
			{
				/*<div
					className="text-center text-[#ddd] font-semibold text-shadow-xl"
					dangerouslySetInnerHTML={{ __html: localize(props.desc) }}
				/>*/
			}

		</div>
	)
}

export const PaymentMethodPopup = () => {
	const [details, setDetails] = React.useState(false)
	const [confirmRules, setConfirmRules] = React.useState(true)
	const [autoSub, setAutoSub] = React.useState(true)

	const [searchParams, setSearchParams] = useSearchParams()
	const productId = searchParams.get("productId") as TPopupType

	if (is_nothing(productId)) {
		clearSearchParams(searchParams);
		setSearchParams(searchParams)

		return <></>
	}

	const isDotaPlusProduct = productId.includes("dota_plus");

	return (
		<Popup>
			<div className="bg-gradient-to-b from-[#242E33] to-[#0E1619] border rounded-md border-[#212121] shadow-custom-xs p-5 flex flex-col items-center text-white">
				<div className="mt-5 text-4xl lg:text-5xl text-[#fedf84] font-bold text-shadow-2xl">
					{localize(`payment_product_${productId}`)}
				</div>
				<div className="mt-10 text-[#ddd] font-bold text-xl lg:text-2xl text-shadow-2xl">
					{localize("payment_select_method")}
				</div>
				<div className="mt-4 pb-2 px-8 flex grid grid-cols-1 sm:grid-cols-3 items-center justify-center">
					<PaymentMethodButton productId={productId} sym="₽" large={true} desc="payment_method_rub"
						disabled={!confirmRules} onClick={() => create_order(productId, "rub", autoSub)} />
					<PaymentMethodButton productId={productId} sym="FunPay" desc="" onClick={() => {
						searchParams.set("popup", "payment_funpay")
						setSearchParams(searchParams)
					}} />
					<PaymentMethodButton productId={productId} sym="Patreon" desc="" onClick={() => {
						searchParams.set("popup", "payment_patreon")
						setSearchParams(searchParams)
					}} />
				</div>
				{
					isDotaPlusProduct
						? <div className="w-full">
							<button
								className="mt-2 w-full h-16 bg-black bg-opacity-25 hover:bg-white hover:bg-opacity-50 text-center font-semibold text-3xl text-shadow-xl"
								onClick={() => setDetails(!details)}
							>
								{localize("payment_details_button")}
							</button>
							<div className={`w-full ${details ? "" : "max-h-0 invisible"} overflow-y-hidden border-t-2 border-gray-600 flex flex-col  `}>
								<div className="mt-4">
									<div>
										<input type="checkbox" className="mr-2"
											defaultChecked={confirmRules}
											onChange={() => setConfirmRules(!confirmRules)}
										/>
										<label>
											{localize("payment_checkbox_approve")}
										</label>
									</div>
									<div className="mt-4">
										<label>
											{localize("payment_email_notification")}
										</label>
										<p>
											<input className="text-input-nobg shadow-custom-xs rounded-md mt-2 w-[300px] lg:w-[400px]"
												type="text"
											/>
										</p>
									</div>
								</div>
								<a className="mt-10 text-center" href="/docs/tinkoff_offer_payments.pdf" target="__blank">
									<u>
										{localize("payment_offer_payments")}
									</u>
								</a>
								<a className="text-center" href="/docs/tinkoff_offer_user.pdf" target="__blank">
									<u>
										{localize("payment_offer_user")}
									</u>
								</a>
							</div>
						</div> : <></>
				}
			</div>
		</Popup>
	)
}

const BuyShardsButton: React.FC<{
	productId: TProductId
	price: number
	amount: number
	currency: TCurrency
	discount?: number
}> = props => {
	const [searchParams, setSearchParams] = useSearchParams()
	const discount = props.discount ? props.discount.toString() : ""
	const price = (props.price || 1337).toString()

	return (
		<div className="mx-5 border border-[#ccc] hover:brightness-125 p-2 flex flex-col items-center">
			<div
				style={background_style(`/images/products/${props.productId}.png`)}
				className={`mx-1 w-64 h-[10.7rem]`}
			>
				{discount !== "" ?
					<div className="bg-gradient-to-r from-transparent to-[#71f33790] flex w-16 ml-auto text-right flex flex-col px-2 py-1 text-[#ddd] text-shadow font-bold text-lg">
						{`-${props.discount}%`}
					</div>
					:
					undefined
				}
			</div>
			<div className="mt-2 text-xl font-semibold uppercase text-white text-shadow-white">
				{localize("buy_shards_product_desc")
					.replace("%amount%", props.amount.toString())
				}
			</div>
			<button
				className="mt-1 w-[12rem] bg-gradient-to-r from-[#d62c00] to-[#ff7f00] text-base font-bold text-white text-shadow-xl p-2"
				onClick={() => {
					searchParams.set("popup", "payment_method")
					searchParams.set("productId", props.productId)
					setSearchParams(searchParams)
				}}
			>
				{localize("buy_shards_price_desc").replace("%price%", props.price.toString())}
			</button>
		</div>
	)
}

export const BuyShardsPopup = () => {
	const prices = R_get<{
		productId: TProductId
		prices: TKV<number, TCurrency>
		amount: number
		extraData: { discount?: number }
	}[]>("/api/payment/data/shards")
	const language = R_subscribe("language")

	let currency: TCurrency = "usd"

	if (language === "ru") {
		currency = "rub"
	}

	return (
		<Popup>
			<div className="bg-gradient-to-b from-[#242E33] to-[#0E1619] border rounded-md border-[#212121] shadow-custom-xs p-5 p-4 px-8 flex flex-col items-center">
				<div className="text-[#fedf84] text-3xl lg:text-6xl font-bold text-shadow-xl">
					{localize("buy_shards_title")}
				</div>
				<div
					className="mt-8 text-center font-semibold text-white text-xl text-shadow-xl"
					dangerouslySetInnerHTML={{ __html: localize("buy_shards_desc") }}
				/>
				<div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
					{prices ? prices.map(s =>
						<BuyShardsButton
							productId={s.productId}
							price={s.prices[currency]}
							currency={currency}
							amount={s.amount}
							discount={s.extraData.discount}
						/>
					) : <Loading />}
				</div>
			</div>
		</Popup>
	)
}

const BuyDotaPlusButton: React.FC<{
	productId: TProductId
	price: number
	currency: TCurrency
	discount?: number
}> = props => {
	const [searchParams, setSearchParams] = useSearchParams()
	const discount = props.discount ? props.discount.toString() : ""
	const price = (props.price || 1337).toString()

	return (
		<div className="m-4 flex flex-col items-center">
			<div className="font-semibold uppercase text-[#ffc97b]" dangerouslySetInnerHTML={{
				__html: localize(`buy_product_${props.productId}_desc`)
					.replace("%price%", price)
					.replace("%discount%", discount)
			}} />
			<button
				className="w-[15rem] lg:w-[16rem] bg-gradient-to-l from-[#f9e0bb] to-[#ffc97a] text-md lg:text-lg font-bold uppercase text-white text-shadow-xl p-2"
				onClick={() => {
					searchParams.set("popup", "payment_method")
					searchParams.set("productId", props.productId)
					setSearchParams(searchParams)
				}}
			>
				{localize(`buy_product_${props.productId}`)}
			</button>
		</div>
	)
}

export const BuyDotaPlusPopup = () => {
	const prices = R_get<{
		productId: TProductId
		prices: TKV<number, TCurrency>
		extraData: {
			discount?: number
			pricesForEach?: TKV<number, TCurrency>
		}
	}[]>("/api/payment/data/dota_plus")

	const language = R_subscribe("language")

	let currency: TCurrency = "usd"

	if (language === "ru") {
		currency = "rub"
	}

	return (
		<Popup>
			<div className="bg-gradient-to-b from-[#272d2e] to-[#131617] border border-[#5e6869] flex flex-col items-center">
				<div className="mx-auto my-6 text-[#fedf84] text-5xl p-5 lg:p-0 lg:text-6xl font-bold text-shadow-xl">
					{localize("dota_plus_title")}
				</div>
				<SizedImage className="mb-4 w-[5.7rem] h-24" imgClassName="w-full opacity-[0.4]" src="/images/dota_plus.png" />
				<div className="">
					{prices ?
						<div className="flex grid grid-cols-1 sm:grid-cols-3">
							{prices.map(d =>
								<BuyDotaPlusButton
									productId={d.productId}
									price={d.extraData.pricesForEach ? d.extraData.pricesForEach[currency] : d.prices[currency]}
									currency={currency}
									discount={d.extraData.discount}
								/>
							)}
						</div>
						: <Loading />}
				</div>
			</div>
		</Popup>
	)
}