import React from "react"
import { R_subscribe, R_get, R_get_stats } from "../components/events"
import { Link, useParams } from "react-router-dom"
import { localize, getLanguage } from "../components/localization"
import { SizedImage, Loading } from "./samples";
import { get, set_state, get_state } from "../components/events"
import { getClearHeroName, get_percent } from "../components/utils"

export const Voting = () => {
	const lang = getLanguage();

	const voting = R_get_stats<TVoting>("/api/voting");

	return <div className={`h-[100vh]`}>
		<div style={{ backgroundImage: `url( 'images/dota_plus/flow/1.png' )` }} className={`
 w-full h-[100%] bg-[length:100%_100%] bg-cover shadow-custom
` }>
			{voting
				? <VotingBlock voting={voting} />
				: <Loading />}
		</div>
	</div >
}

const VotingBlock: React.FC<{
	voting: TVoting
}> = props => {
	return props.voting.isActive
		? <VotingInProgressBlock voting={props.voting} />
		: <VotingClosedBlock voting={props.voting} />
}

const VotingClosedBlock: React.FC<{
	voting: TVoting
}> = props => {
	return <div className="text-[#fedf84] text-5xl font-bold text-shadow text-center">
		<div className={`py-10 header-blue`}>
			{localize("voting_end")}
		</div>
		<div className="mt-10 pt-10 flex flex-col justify-center">
			<div className="text-4xl text-[#fedf84]">
				{localize("voting_end_hero")}
			</div>
			<div className="flex mt-5 justify-center">
				<SizedImage
					className="h-16 overflow-hidden shadow-sm shadow-custom h-[200px] w-[380px]"
					imgClassName="w-full h-full"
					src={`images/heroes/${props.voting.heroWon}.png`}
				/>
			</div>
		</div>
	</div>
}

const VotingInProgressBlock: React.FC<{
	voting: TVoting
}> = props => {
	let totalVotesCount = 0;
	let heroNameWithMaxVotes = '';
	let votesCountWithMaxVotes = 0;

	props.voting.heroes.forEach(h => {
		totalVotesCount += h.voteCount;

		if (votesCountWithMaxVotes < h.voteCount) {
			votesCountWithMaxVotes = h.voteCount;
			heroNameWithMaxVotes = h.heroName;
		}
	});

	return <div>
		<div className={`py-10 text-[#fedf84] text-5xl font-bold header-blue`}>
			{localize("voting_title")}
		</div>
		<div className="flex flex-col justify-center mt-10 pt-10">
			{props.voting.heroes.map(h =>
				<VotingHero votingHero={h}
					totalVotesCount={totalVotesCount}
					heroNameWithMaxVotes={heroNameWithMaxVotes}
					votesCountWithMaxVotes={votesCountWithMaxVotes}
				/>
			)}
			<div className="flex flex-col text-end px-10 mx-10 text-[#ccc] text-2xl text-shadow">
				<p>
					{localize("voting_end_date")}<b>{props.voting.endDateString}</b>
				</p>
				<p>
					{localize("voting_place")}
				</p>
			</div>
		</div>
	</div >
}

const VotingHero: React.FC<{
	votingHero: TVotingHero,
	totalVotesCount: number,
	votesCountWithMaxVotes: number
	heroNameWithMaxVotes: string
}> = props => {
	const votingHero = props.votingHero;
	const votesCountWithMaxVotes = props.votesCountWithMaxVotes;
	const progressValue = votingHero.heroName == props.heroNameWithMaxVotes
		? votesCountWithMaxVotes * 0.95
		: votingHero.voteCount;

	return <div className="flex flex-row justify-center mb-10 pb-10">
		<div className="bg-black">
			<SizedImage
				className="h-16 overflow-hidden shadow-sm shadow-custom h-[100px] w-[180px]"
				imgClassName="w-full h-full"
				src={`images/heroes/${votingHero.heroName}.png`}
			/>
		</div>
		<div className="flex flex-col items-center mt-3">
			<progress className="h-[50px] w-[800px] p-1" id="drowBox" max={votesCountWithMaxVotes} value={progressValue} />
			<div className="flex text-[#fedf84] text-start-im text-3xl text-shadow-2xl w-[100%] pl-2 font-bold">
				{`${votingHero.voteCount} (${get_percent(votingHero.voteCount, props.totalVotesCount)}%)`}
			</div>
		</div>
	</div>
}
