import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import "./index.css"
import { Main } from "./render/main"
import { init_main } from "./components/main"

(async () => {
	await init_main()

	console.log("ROFl")

	ReactDOM
		.createRoot(document.getElementById("root") as HTMLElement)
		.render(
			<BrowserRouter>
				<Main />
			</BrowserRouter>
		)
})()