import { get, set_state, get_state } from "./events"

export const AVAILABLE_LANGUAGES: readonly TLanguage[] = ["en", "ru"]

const localizations: TKV<TKV<string>> = {}

export function is_available_language(lang: string): lang is TLanguage {
	return AVAILABLE_LANGUAGES.includes(lang as TLanguage)
}

export function set_language(lang: string) {
	if (is_available_language(lang)) {
		localStorage.setItem("language", lang)
		set_state("language", lang)
	}
}

export function getLanguage() {
	return get_state("language");
}

export function localize(key: string) {
	const lang = get_state("language") || "en"
	const loc = localizations[lang] || {}

	return loc[key] || localizations.en[key] || key
}

export function localize_price(price: number | string, currency: TCurrency) {
	return `${price} ${localize_currency_symbol(currency)}`;
}

export function localize_currency_symbol(currency: TCurrency) {
	switch (currency) {
		case "usd":
			return "$";
		case "rub":
			return "₽";
		case "eur":
			return "€";
	}
}

export async function init_localization() {
	const saved = localStorage.getItem("language")

	if (saved && is_available_language(saved)) {
		set_language(saved)
	} else if (window.navigator.language) {
		const browserLang = window.navigator.language.slice(0, 2);

		if (is_available_language(browserLang))
			set_language(browserLang)
		else
			set_language("ru")
	}
	else {
		set_language("ru")
	}

	for (const lang of AVAILABLE_LANGUAGES) {
		const data = await get(`/localization/${lang}.json`)

		if (data) {
			localizations[lang as TLanguage] = data
		}
	}
}

export function localizeTimeAgo(count: number, type: "day" | "week" | "hour") {
	const lang = getLanguage();
	const isEnLang = lang == "en";

	let value;
	switch (type) {
		case "day":
			value = isEnLang
				? getUpdateAgoEnValue(count, "dota_update_days_ago")
				: getUpdateAgoRuValue(count, "dota_update_days_ago");
			break;

		case "week":
			value = isEnLang
				? getUpdateAgoEnValue(count, "dota_update_weeks_ago")
				: getUpdateAgoRuValue(count, "dota_update_weeks_ago");
			break;

		case "hour":
			value = isEnLang
				? getUpdateAgoEnValue(count, "dota_update_hours_ago")
				: getUpdateAgoRuValue(count, "dota_update_hours_ago");
			break;
	}

	return isEnLang ? value + " ago" : value + " назад";
}

export function getGameStage(gameStage: TGameStage): string {
	switch (gameStage) {
		case 'start':
			return localize('dota_game_stage_start');
		case 'early':
			return localize('dota_game_stage_early');
		case 'mid':
			return localize('dota_game_stage_mid');
		case 'late':
			return localize('dota_game_stage_late');
	}
}

function getUpdateAgoEnValue(count: number, value: string) {
	const values = localize(value).split("|");

	return count > 1
		? values[0]
		: values[1];
}

function getUpdateAgoRuValue(count: number, value: string) {
	const values = localize(value).split("|");
	const countLastNumber = parseInt(count.toString().slice(-1));

	switch (countLastNumber) {
		case 1:
			return values[0];
		case 2:
		case 3:
		case 4:
			return values[1];
		default:
			return values[2];
	}
}