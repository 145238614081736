import React, { useState } from "react"
import { NavLink, Routes, Route, useSearchParams, useLocation } from "react-router-dom"
import { localize, AVAILABLE_LANGUAGES, set_language, getLanguage } from "../components/localization"
import { R_subscribe, set_state, R_get } from "../components/events"
import { sign_in, sign_out } from "../components/auth"
import { is_nothing, R_on_click_outside, R_on_change_location } from "../components/utils"
import { DotaPlus } from "./dota_plus"
import { Leaderboard } from "./leaderboard"
import { HeroesMeta } from "./heroMeta"
import { HeroesList } from "./heroes"
import { Updates } from "./updates"
import { Update } from "./update"
import { Voting } from "./voting"
import {
	BuyShardsPopup,
	BuyDotaPlusPopup,
	PaymentMethodPopup,
	PaymentPatreonPopup,
	PaymentFunPayPopup,
	PromocodePopup,
	NoticePopup,
	PresentPopup
} from "./popup"
import { Loading, SizedImage, DropDown } from "./samples"
import { Hero } from "./hero"
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'

const NavMenuMobile: React.FC<{
	onShowMobileNagitation: any
}> = props => {
	const selectedLang = R_subscribe("language")

	return <>
		<div className="">
			<div className="flex justify-between items-center text-[#fedf84]">
				<Bars3Icon className="h-7 w-7"
					onClick={() => props.onShowMobileNagitation()}
				/>
				<span className="text-[24px] font-bold">Dota 1x6</span>
				<button className="px-4" onClick={() => set_language(selectedLang === "ru" ? "en" : "ru")}>
					<SizedImage className="h-5 w-5" src={`/images/flags/${selectedLang}.png`} />
				</button>
			</div>
		</div>
	</>
}

const Profile = () => {
	const authSummary = R_subscribe("authorized")

	const [searchParams, setSearchParams] = useSearchParams()

	R_subscribe("language")

	return (
		<div className="m-2 mb-6 w-[15rem] flex w-full items-center text-xl text-gray-300">
			{
				authSummary ?
					authSummary.authorized ?
						<div className="w-full flex flex-col" >
							<div className="flex items-center w-full">
								<SizedImage className="w-10 h-10" imgClassName="rounded-md brightness-100" src={authSummary.avatarUrl} />
								<a className={`
									ml-2 max-w-[10.3rem] overflow-hidden flex-1
									text-xl text-gray-300 hover:text-white
									transition transition-all ease-linear duration-200
								` } href={authSummary.steamUrl} target="_blank">
									{authSummary.nickname}
								</a>
								<button className="w-7 h-7 brightness-75 hover:brightness-100 transition transition-all ease-linear duration-100" onClick={() => sign_out()}>
									<SizedImage src="/images/logout.png" />
								</button>
							</div>
							<div className="text-shards mt-2 flex items-center justify-center text-3xl text-[#fedf84] font-semibold">
								<SizedImage className="w-9 h-9" src="/images/shards.png" />
								{authSummary.shardsAmount}
							</div>
							<button
								className={`
									shadow-gold-small text-buttom-small mt-3 w-[15rem] h-10 bg-gradient-to-r from-[#d35f04] to-[#d0af02]
									brightness-90 hover:brightness-100 transition transition-all ease-linear duration-100
									flex items-center justify-center text-white font-bold
								` }
								onClick={() => {
									searchParams.set("popup", "shards")
									setSearchParams(searchParams)
								}}
							>
								{localize("buy_shards")}
							</button>
							<button
								className={`
									shadow-gold-small text-buttom-small mt-3 w-[15rem] h-10 bg-gradient-to-r from-[#1763d4] to-[#1aa3d9]
									brightness-95 hover:brightness-150 transition transition-all ease-linear duration-100
									flex items-center justify-center text-white font-bold
								` }
								onClick={() => {
									searchParams.set("popup", "promocode")
									setSearchParams(searchParams)
								}}
							>
								{localize("dota_promocode")}
							</button>
							{getLanguage() !== "ru"
								? <></>
								: <button
									className={`
									shadow-gold-small text-buttom-small mt-3 w-[15rem] h-10 bg-gradient-to-r from-[#d35f04] to-[#d0af02]
									brightness-90 hover:brightness-100 transition transition-all ease-linear duration-100
									flex items-center justify-center text-white font-bold
								` }
									onClick={() => {
										searchParams.set("popup", "present")
										setSearchParams(searchParams)
									}}
								>
									{localize("buy_present")}
								</button>
							}
						</div>
						:
						<button
							className={`
								w-[15rem] h-9 bg-gradient-to-r from-[#428039] to-[#54ba46] shadow-custom
								brightness-90 hover:brightness-100 transition transition-all ease-linear duration-100
								flex items-center justify-center text-2xl text-white font-semibold text-shadow
							` }
							onClick={() => sign_in()}
						>
							{localize("steam_sign_in")}
							<SizedImage className="w-6 h-6" src="/images/steam.png" />
						</button>
					:
					<div>Loading...</div>
			}
		</div>
	)
}

const NavMenu = () => {
	return (
		<>
			<Profile />
			<br className="hidden xl:block" />
			<NavButton url="/" type="dota_plus" text="nav_dota_plus" textColor="text-[#fedf84]" />
			<NavButton url="/leaderboard" type="leaderboard" text="nav_leaderboard" textColor="text-[#fedf84]" />
			<NavButton url="/stats" type="meta" text="nav_stats" textColor="text-[#fedf84]" />
			<NavButton url="/heroes" type="heroes" text="nav_heroes" textColor="text-[#fedf84]" />
			<NavButton url="/voting" type="voting" text="nav_voting" textColor="text-[#fedf84]" />
		</>
	)
}

const NavButton: React.FC<{ url: string, type: "dota_plus" | "leaderboard" | "heroes" | "meta" | "wiki" | "updates" | "voting", text: string, textColor: string }> = props =>
	<NavLink end={props.type === "dota_plus"} to={props.url} style={{ backgroundImage: GetImagePath(props.type) }} className={s => `
		m-2 xl:mb-4
		w-[15rem] h-[4.5rem]
		border-solid border bg-cover
		${s.isActive ? "border-[#fedf84] shadow-gold brightness-100 hover:brightness-125" : "border-black shadow-custom brightness-100 hover:brightness-125"}
		transition transition-all ease-linear duration-100
		flex flex-row items-center
	` }>
		<div className={`text-2xl text-left ml-4 text-shadow-xl font-semibold ${props.textColor}`}>
			{localize(props.text)}
		</div>
	</NavLink>

export const Main = () => {
	const langDDRef = React.useRef<HTMLDivElement>(null)
	const mobileNavigationsRef = React.useRef<HTMLDivElement>(null)

	const [langDD, setLangDD] = React.useState(false)
	const selectedLang = R_subscribe("language")
	const [searchParams, setSearchParams] = useSearchParams()
	const [showMobileNavigations, setShowMobileNavigations] = useState(false);

	const popup = searchParams.get("popup") as TPopupType

	R_on_click_outside(langDDRef, () => setLangDD(false))

	R_on_click_outside(mobileNavigationsRef, () => {
		setShowMobileNavigations(false);
	})

	R_on_change_location(() => {
		setShowMobileNavigations(false);
	})


	return (
		<div className="flex flex-col items-center">
			<div className="w-full xl:w-[89.25rem] xl:flex font-serif">
				{showMobileNavigations
					? <div className="xl:hidden block nav-blocks-container z-[100] fixed h-full bg-gradient-to-t from-[#090019] to-[#010036] border-r border-solid border-[#3a3632] shadow-custom flex flex-col"
						ref={mobileNavigationsRef}
					>
						<NavMenu />
					</div>
					: <></>
				}
				<div className="hidden xl:block nav-blocks-container z-[10] fixed h-full p-4 bg-gradient-to-t from-[#090019] to-[#010036] border-r border-solid border-[#3a3632] shadow-custom flex flex-col">
					<NavMenu />
				</div>
				<div className="xl:hidden p-4 bg-gradient-to-t from-[#090019] to-[#1D1C6A] shadow-custom flex flex-col">
					<NavMenuMobile
						onShowMobileNagitation={() => {
							setShowMobileNavigations(true)
						}}
					/>
				</div>
				<div className="xl:ml-[18rem] ml-0 h-full flex-1">
					<Routes>
						<Route path="/" element={<DotaPlus />} />
						<Route path="/leaderboard" element={<Leaderboard />} />
						<Route path="/stats" element={<HeroesMeta />} />
						<Route path="/heroes" element={<HeroesList />} />
						<Route path="/hero/:name" element={<Hero />} />
						<Route path="/voting" element={<Voting />} />
					</Routes>
				</div>
				<div className="hidden xl:block z-[10] fixed top-0 mt-3 ml-0 right-0 xl:ml-[96.25rem] xl:right-auto">
					<button className="px-4" onClick={() => set_language(selectedLang === "ru" ? "en" : "ru")}>
						<SizedImage className="h-5 w-5" src={`/images/flags/${selectedLang}.png`} />
					</button>
				</div>
				{
					renderPopup(popup)
				}
			</div>
		</div>
	)
}

function renderPopup(popup: TPopupType) {
	switch (popup) {
		case "dota_plus":
			return <BuyDotaPlusPopup />
		case "shards":
			return <BuyShardsPopup />
		case "payment_method":
			return <PaymentMethodPopup />
		case "payment_funpay":
			return <PaymentFunPayPopup />
		case "payment_patreon":
			return <PaymentPatreonPopup />
		case "notice":
			return <NoticePopup />
		case "promocode":
			return <PromocodePopup />
		case "present":
			return <PresentPopup type="buy" />
		case "present_history":
			return <PresentPopup type="history" />
		default:
			return undefined;
	}
}

//быстрый костыль на проблему с путями до изображений вызвываемых вложеными роутами
function GetImagePath(name: string) {
	const location = useLocation();

	return location.pathname.match("\/updates\/\S*")
		? `url( '../images/nav/${name}.png' )`
		: `url( '/images/nav/${name}.png' )`;
}